<template>
    <div class="page-body-wrapper">
      <div class="page-body">
        <div class="container-fluid">
          <div class="page-title">
            <div class="row">
              <div class="col-12">
                <h3>Изменить кафедру</h3>
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid">
          <div class="card">

            <div class="card-body">
              <form class="theme-form">
                <div class="mb-3">
                  <label class="col-form-label pt-0" for="nameInput">Название кафедры</label>
                  <input v-model="form.name" class="form-control" type="text" placeholder="Введите название кафедры">
                </div>
    
                <div class="mb-3">
                  <label class="col-form-label pt-0" for="settingIdInput">Факультеты</label>
                  <select v-model="form.faculty_id" class="form-control">
                    {{ faculties }}
                    <option v-for="faculty in faculties" :key="faculty.id" :value="faculty.id">{{ faculty.name }}</option>
                  </select>
                </div>
         
                <button @click.prevent="submitForm" class="btn btn-primary">Изменить</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        form: {
          name: '',
          faculty_id: null,
        },
        faculties: []
      };
    },
    created() {
      
      this.fetchSettings();
    },
    methods: {
      async fetchSettings() {
        try {
   
            const response = await this.$axios.get(`/faculties`);
            this.faculties = response.data; // Сохраняем полученные настройки в массив
        } catch (error) {
          console.error('Ошибка загрузки настроек:', error);
        }

        try {
            const response = await this.$axios.get(`/departaments/${this.$route.params.id}`);
            // После получения данных настройки заполняем форму
            this.form.faculty_id = response.data.faculty_id;
            this.form.name = response.data.name;
        } catch (error) {
            console.error('Ошибка получения настройки:', error);
        }
      },
      async submitForm() {
        try {
          const response = await this.$axios.put(`/departaments/${this.$route.params.id}`, this.form);
          console.log('Успешно отправлено:', response.data);

            this.$router.push('/faculties');

          // Здесь вы можете выполнить другие действия после успешной отправки данных на сервер
        } catch (error) {
          console.error('Ошибка отправки:', error);
        }
      }
    }
  };
  </script>
  