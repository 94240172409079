<template>
  <div id="app">
    <header-component />
    <router-view></router-view>
    <footer-component />
  </div>
</template>

<script>

import HeaderComponent from './components/template/HeaderTemplate.vue';
import FooterComponent from './components/template/FooterTemplate.vue';

export default {
  name: 'App',

  components: {
    HeaderComponent,
    FooterComponent,
  },
};
</script>
