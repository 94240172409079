<template>
        
    <!-- Page Sidebar Ends-->
    <div class="page-body-wrapper">
        <div class="page-body">
        <div class="container-fluid">
            <div class="page-title">
            <div class="row">
                <div class="col-sm-6">
                    <h3>Группы</h3> ( <a href="/groups/add">Добавить группу</a>)

                   
                </div>

                <div class="col-sm-6">
                    
                </div>
            </div>
            </div>
        </div>
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row starter-main">
                <div class="col-lg-6 col-12" v-for="data in Dates" :key="data.id">
                    <div class="card o-hidden product-widget">
                        <div class="card-header pb-0">
                            <div class="d-flex"> 
                                <div class="flex-grow-1"> 
                                    <h4>{{ data.name }}</h4>
                                    <br>

                                    <a :href="`/groups/edit/${data.id}`" class="btn btn-success w-100 my-2">Изменить расписание</a>
                                    <a :href="`/groups/edit/zachety/${data.id}`" class="btn btn-success w-100 my-2">Изменить расписание зачетов</a>
                                    <a :href="`/groups/edit/exameny/${data.id}`" class="btn btn-success w-100 my-2">Изменить расписание экзаменов</a>
                                    <a @click="destroy(data.id)" class="btn btn-danger w-100 my-2">Удалить группу</a>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
        <!-- Container-fluid Ends-->
        </div>
    </div>
    

</template>


<script>
export default {
    data() {
        return {
            allData: [],
        };
    },

    created() {
        this.fetchAllData();
    },

    computed: {
        Dates() {
            return this.allData;
        }
    },

    methods: {
        async fetchAllData() {
            try {
                const response = await this.$axios.get(`/groups/${this.$route.params.id}`);

                console.log(response)

                this.allData = response.data.map(groups => ({
                    ...groups,
                }));
            } catch (error) {
                console.error('Ошибка получения данных:', error);
            }
        },

        async fetchDepartments() {
            for (const faculty of this.allData) {
                try {
                    const response = await this.$axios.get(`/fdepartaments/${faculty.id}`);
                    faculty.dep = response.data;
                } catch (error) {
                    console.error('Ошибка получения данных о кафедрах:', error);
                }
            }
        },

        async destroy(id) {
            try {
                const response = await this.$axios.delete(`/groups/${id}`);
                // Для проверки выводим данные в консоль
                console.log('Удаление прошло успешно', response.data);

                this.fetchAllData();

            } catch (error) {
                console.error('Ошибка получения данных:', error);
            }
        }
    }
};
</script>

