<template>
    <div class="page-body-wrapper">
        <div class="page-body">
            <div class="container-fluid">
                <div class="page-title">
                    <div class="row">
                        <div class="col-sm-6">
                            <h3>Изменить расписание</h3>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container-fluid">
                <div class="card">
                    <div class="card-body">
                        <form class="theme-form">
                            <!-- Первая неделя -->
                            <h3>Первая неделя</h3>
                            <div class="accordion" id="accordionWeek1">
                                <div v-for="dayIndex in 7" :key="'week1_day' + dayIndex" class="accordion-item">
                                    <h2 class="accordion-header" :id="'headingWeek1Day' + dayIndex">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapseWeek1Day' + dayIndex" aria-expanded="dayIndex === 1" :aria-controls="'collapseWeek1Day' + dayIndex">
                                            {{ getDayName(dayIndex) }}
                                        </button>
                                    </h2>
                                    <div :id="'collapseWeek1Day' + dayIndex" class="accordion-collapse collapse" :class="{ show: dayIndex === 1 }" :aria-labelledby="'headingWeek1Day' + dayIndex" data-bs-parent="#accordionWeek1">
                                        <div class="accordion-body">
                                            <div v-for="pairIndex in 10" :key="'week1_day' + dayIndex + '_pair' + pairIndex">
                                                <br>
                                                <h4>Пара {{ pairIndex }}</h4>
                                                <br>
                                                <div class="mb-3">
                                                    <label class="col-form-label pt-0">Преподаватель</label>
                                                    <input v-model="formData[`week1_day${dayIndex}_pair${pairIndex}_teacher`]" class="form-control" type="text" placeholder="Преподаватель">
                                                </div>
                                                <div class="mb-3">
                                                    <label class="col-form-label pt-0">Предмет</label>
                                                    <input v-model="formData[`week1_day${dayIndex}_pair${pairIndex}_subject`]" class="form-control" type="text" placeholder="Предмет">
                                                </div>
                                                <div class="mb-3">
                                                    <label class="col-form-label pt-0">Кабинет</label>
                                                    <input v-model="formData[`week1_day${dayIndex}_pair${pairIndex}_cabinet`]" class="form-control" type="text" placeholder="Кабинет">
                                                </div>
                                                <div class="mb-3">
                                                    <label class="col-form-label pt-0">Описание</label>
                                                    <input v-model="formData[`week1_day${dayIndex}_pair${pairIndex}_description`]" class="form-control" type="text" placeholder="Описание">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br><br>
                            <!-- Вторая неделя -->
                            <h3>Вторая неделя</h3>
                            <div class="accordion" id="accordionWeek2">
                                <div v-for="dayIndex in 7" :key="'week2_day' + dayIndex" class="accordion-item">
                                    <h2 class="accordion-header" :id="'headingWeek2Day' + dayIndex">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapseWeek2Day' + dayIndex" aria-expanded="false" :aria-controls="'collapseWeek2Day' + dayIndex">
                                            {{ getDayName(dayIndex) }}
                                        </button>
                                    </h2>
                                    <div :id="'collapseWeek2Day' + dayIndex" class="accordion-collapse collapse" :aria-labelledby="'headingWeek2Day' + dayIndex" data-bs-parent="#accordionWeek2">
                                        <div class="accordion-body">
                                            <div v-for="pairIndex in 10" :key="'week2_day' + dayIndex + '_pair' + pairIndex">
                                                <br>
                                                <h4>Пара {{ pairIndex }}</h4>
                                                <br>
                                                <div class="mb-3">
                                                    <label class="col-form-label pt-0">Преподаватель</label>
                                                    <input v-model="formData[`week2_day${dayIndex}_pair${pairIndex}_teacher`]" class="form-control" type="text" placeholder="Преподаватель">
                                                </div>
                                                <div class="mb-3">
                                                    <label class="col-form-label pt-0">Предмет</label>
                                                    <input v-model="formData[`week2_day${dayIndex}_pair${pairIndex}_subject`]" class="form-control" type="text" placeholder="Предмет">
                                                </div>
                                                <div class="mb-3">
                                                    <label class="col-form-label pt-0">Кабинет</label>
                                                    <input v-model="formData[`week2_day${dayIndex}_pair${pairIndex}_cabinet`]" class="form-control" type="text" placeholder="Кабинет">
                                                </div>
                                                <div class="mb-3">
                                                    <label class="col-form-label pt-0">Описание</label>
                                                    <input v-model="formData[`week2_day${dayIndex}_pair${pairIndex}_description`]" class="form-control" type="text" placeholder="Описание">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br>
                            <br>
                            <button @click.prevent="submitForm" class="btn btn-primary">Изменить</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>



<script>
export default {
    data() {
        return {
            formData: this.initializeFormData(),
            dep_id: null
        };
    },

    methods: {
        initializeFormData() {
            const data = {};
            for (let week = 1; week <= 2; week++) {
                for (let day = 1; day <= 7; day++) {
                    for (let pair = 1; pair <= 10; pair++) {
                        data[`week${week}_day${day}_pair${pair}_teacher`] = '';
                        data[`week${week}_day${day}_pair${pair}_subject`] = '';
                        data[`week${week}_day${day}_pair${pair}_cabinet`] = '';
                        data[`week${week}_day${day}_pair${pair}_description`] = '';
                    }
                }
            }

            return data;
        },

        async fetchFormData() {
            try {
                const response = await this.$axios.get(`/group/${this.$route.params.id}`);


                this.dep_id = response.data.departament_id
              
                if (response.data.schedule != null) {
                    this.formData = JSON.parse(response.data.schedule);
                } else {
                    this.formData = this.initializeFormData()
                }
            } catch (error) {
                console.error('Ошибка загрузки данных:', error);
            }
        },

        async submitForm() {
            try {
                const response = await this.$axios.put(`/groups/${this.$route.params.id}`, {
                    schedule: JSON.stringify(this.formData) // обертываем данные в ключ schedule
                });
                console.log('Успешно отправлено:', response.data);
                this.$router.push(`/dep/${this.dep_id}`);
            } catch (error) {
                console.error('Ошибка отправки:', error);
            }
        },

        getDayName(dayIndex) {
            const days = ['Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота', 'Воскресенье'];
            return days[dayIndex - 1];
        }
    },

    mounted() {
        this.fetchFormData();
    }
};
</script>
