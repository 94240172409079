<template>
    <!-- tap on top starts-->
    <div class="tap-top"><i data-feather="chevrons-up"></i></div>
    <!-- tap on tap ends-->
    <!-- Loader starts-->
    <!-- <div class="loader-wrapper">
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"> </div>
      <div class="dot"></div>
    </div> -->

    
    <!-- Page Header Start-->
    <div class="page-header">
        <div class="header-wrapper row m-0">
            <form class="form-inline search-full col" action="#" method="get">
            <div class="form-group w-100">
                <div class="Typeahead Typeahead--twitterUsers">
                <div class="u-posRelative">
                    <input class="demo-input Typeahead-input form-control-plaintext w-100" type="text" placeholder="Search Tivo .." name="q" title="" autofocus="">
                    <div class="spinner-border Typeahead-spinner" role="status"><span class="sr-only">Loading...</span></div><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x close-search"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                </div>
                <div class="Typeahead-menu"></div>
                </div>
            </div>
            </form>
            <div class="header-logo-wrapper col-auto p-0">
            <div class="toggle-sidebar"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-grid status_toggle middle sidebar-toggle"><rect x="3" y="3" width="7" height="7"></rect><rect x="14" y="3" width="7" height="7"></rect><rect x="14" y="14" width="7" height="7"></rect><rect x="3" y="14" width="7" height="7"></rect></svg></div>
            <div class="logo-header-main"><a href="index.html"><img class="img-fluid for-light img-100" src="/assets/images/logo/logo2.png" alt=""><img class="img-fluid for-dark" src="/assets/images/logo/logo.png" alt=""></a></div>
            </div>
            <div class="left-header col horizontal-wrapper ps-0">
            <div class="left-menu-header">
                <ul>
                <li><a href="/faculties">Факультеты</a></li>
                </ul>
            </div>
            </div>
            <div class="nav-right col-6 pull-right right-header p-0">
            <ul class="nav-menus">
      
              
                
                <li class="language-nav" style="opacity: 0;">
                <div class="translate_wrapper">
                    
                    <div class="more_lang">
                    <div class="lang selected" data-value="en"><i class="flag-icon flag-icon-us"></i><span class="lang-txt">English<span> (US)</span></span></div>
                    <div class="lang" data-value="de"><i class="flag-icon flag-icon-de"></i><span class="lang-txt">Deutsch</span></div>
                    <div class="lang" data-value="es"><i class="flag-icon flag-icon-es"></i><span class="lang-txt">Español</span></div>
                    <div class="lang" data-value="fr"><i class="flag-icon flag-icon-fr"></i><span class="lang-txt">Français</span></div>
                    <div class="lang" data-value="pt"><i class="flag-icon flag-icon-pt"></i><span class="lang-txt">Português<span> (BR)</span></span></div>
                    <div class="lang" data-value="cn"><i class="flag-icon flag-icon-cn"></i><span class="lang-txt">简体中文</span></div>
                    <div class="lang" data-value="ae"><i class="flag-icon flag-icon-ae"></i><span class="lang-txt">لعربية <span> (ae)</span></span></div>
                    </div>
                </div>
                </li>
                <li class="profile-nav onhover-dropdown">
                <div class="account-user"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg></div>
                <ul class="profile-dropdown onhover-show-div">
                    
                    <li><a><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-log-in"><path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4"></path><polyline points="10 17 15 12 10 7"></polyline><line x1="15" y1="12" x2="3" y2="12"></line></svg><span>Выйти</span></a></li>
                </ul>
                </li>
            </ul>
            </div>
        </div>
    </div>
    <!-- Page Header Ends-->

 
</template>


<script>
export default {
    data() {
        return {
            menu: [
                { name: 'Список дел', url: '/cases' },
                { name: 'Список вещ доков', url: '/evids' },
                { name: 'Добавить дело', url: '/case/add' },
                { name: 'Добавить Вещ. док', url: '/evid/add' },
                { name: 'Выйти', url: `/logout` },
            ],
        };
    },

    // beforeRouteEnter(to, from, next) {
    //     const isTokenExist = document.cookie.split(';').some((item) => item.trim().startsWith('token='));
    //     next(vm => {
    //         vm.isShow = isTokenExist;
    //     });
    // },

    computed: {
        editMenu() {
            return localStorage.getItem('role') == 'user' ? this.menu.filter((item, index) => index != 2 && index != 3) : this.menu;
        },

        isShow() {
            return this.$route.path != '/login';
        },
    },

    methods: {
        handleMenuClick(item) {
            if (item.url === '/logout') {
                this.logout(localStorage.getItem('id'));
            } else {
                this.$router.push(item.url);
            }
        },

        logout(userId) {
            const logoutUrl = `/logout/${userId}`;

            this.$axios.post(logoutUrl)
                .then(() => {
                    this.$cookies.remove('token');

                    localStorage.removeItem("id");
                    localStorage.removeItem("role");

                    setTimeout(() => {
                        this.$router.push('/login');
                    }, 500);

                })
                .catch(error => {
                    console.error('Logout failed', error);
                    // Обработка ошибки авторизации
                });
        },

        getUserInfo(user_id) {
            this.$axios.get('/user', {
                params: {
                    user_id: user_id
                }
            })
        },

        isRole() {
            const role = localStorage.getItem('role');

            if (role === 'admin' || role === 'control')
                return true;
            return false;
        },
    }
};
</script>

