<template>
  <div class="page-body-wrapper">
    <div class="page-body">
      <div class="container-fluid">
        <div class="page-title">
          <div class="row">
            <div class="col-12">
              <h3>Добавить группу</h3>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid">
        <div class="card">

          <div class="card-body">
            <form class="theme-form">
              <div class="mb-3">
                <label class="col-form-label pt-0" for="nameInput">Название группы</label>
                <input v-model="form.name" class="form-control" type="text" placeholder="Введите название группы">
              </div>
  
              <div class="mb-3">
                <label class="col-form-label pt-0" for="settingIdInput">Кафедры:</label>
                <select v-model="form.departament_id" class="form-control">
                  <option v-for="dep in deps" :key="dep.id" :value="dep.id">{{ dep.name }}</option>
                </select>
              </div>
       
              <button @click.prevent="submitForm" class="btn btn-primary">Создать</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        name: '',
        departament_id: null,
      },
      deps: []
    };
  },
  created() {
    // Здесь можно загрузить список настроек из вашего API
    this.fetchSettings();
  },
  methods: {
    async fetchSettings() {
      try {
          const response = await this.$axios.get('/departaments');
          this.deps = response.data;
      } catch (error) {
        console.error('Ошибка загрузки настроек:', error);
      }
    },
    async submitForm() {
      try {
          await this.$axios.post(`/groups`, this.form);

          this.$router.push(`/dep/${this.form.departament_id}`);
      } catch (error) {
        console.error('Ошибка отправки:', error);
      }
    }
  }
};
</script>
