<template>
    <!-- footer start-->
    <div></div>

</template>

<script>
export default {
    // Определения компонента

    data() {
        return {};
    }
};
</script>
