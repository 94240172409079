<template>
        
    <!-- Page Sidebar Ends-->
    <div class="page-body-wrapper">
        <div class="page-body">
        <div class="container-fluid">
            <div class="page-title">
            <div class="row">
                <div class="col-12">
                    <h3>Факультеты</h3>
                    <br>
                    <a href="/faculties/add">Добавить факультет</a>
                    <br>
                    <a href="/dep/add">Добавить кафедру</a>
                </div>
            </div>
            </div>
        </div>
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row starter-main">
                <div class="col-lg- col-12" v-for="data in Dates" :key="data.id">
                    <div class="card o-hidden product-widget">
                        <div class="card-header pb-0">
                            <div class="d-flex"> 
                                <div class="flex-grow-1"> 
                                    <h4>{{ data.name }}</h4>
                                    <br>
                                    <h6>Кафедры:</h6>
                    
                                    <ol>
                                        <li v-for="d in data.dep" :key="d.id">
                                            <h4>{{ d.name }}</h4>

                                            <a :href="`/dep/${d.id}`" class="btn btn-success w-100 my-2">Перейти на кафедру</a>
                                            <a :href="`/dep/edit/${d.id}`" class="btn btn-success w-100 my-2">Изменить кафедру</a>
                                            <a @click="destroyDep(d.id)" class="btn btn-danger w-100 my-2">Удалить кафедру</a>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>

                        <div class="card-header pt-0">
                            <a :href="`/faculties/edit/${data.id}`" class="btn btn-success w-100 my-2">Изменить факультет</a>
                            <a @click="destroy(data.id)" class="btn btn-danger w-100 my-2">Удалить факультет</a>
                        </div>
                        
                    </div>
                </div>
                
            </div>
        </div>
        <!-- Container-fluid Ends-->
        </div>
    </div>
    

</template>


<script>
export default {
    data() {
        return {
            allData: [],
        };
    },

    created() {
        this.fetchAllData();
    },

    computed: {
        Dates() {
            return this.allData;
        }
    },

    methods: {
        async fetchAllData() {
            try {
                const response = await this.$axios.get('/faculties');
                this.allData = response.data.map(faculty => ({
                    ...faculty,
                    dep: [], // Добавляем пустой массив для хранения кафедр
                }));
                await this.fetchDepartments(); // Получаем кафедры для каждого факультета
            } catch (error) {
                console.error('Ошибка получения данных:', error);
            }
        },

        async fetchDepartments() {
            for (const faculty of this.allData) {
                try {
                    const response = await this.$axios.get(`/fdepartaments/${faculty.id}`);
                    faculty.dep = response.data;
                } catch (error) {
                    console.error('Ошибка получения данных о кафедрах:', error);
                }
            }
        },

        async destroy(id) {
            try {
                const response = await this.$axios.delete(`/faculties/${id}`);
                // Для проверки выводим данные в консоль
                console.log('Удаление прошло успешно', response.data);

                this.fetchAllData();

            } catch (error) {
                console.error('Ошибка получения данных:', error);
            }
        },

        async destroyDep(id) {
            try {
                const response = await this.$axios.delete(`/departaments/${id}`);
                // Для проверки выводим данные в консоль
                console.log('Удаление прошло успешно', response.data);

                this.fetchAllData();

            } catch (error) {
                console.error('Ошибка получения данных:', error);
            }
        }
    }
};
</script>

