<template>
    <div class="page-body-wrapper">
      <div class="page-body">
        <div class="container-fluid">
          <div class="page-title">
            <div class="row">
              <div class="col-12">
                <h3>Изменить поле ({{ form.name }})</h3>
                {{ form.start_date }}
              </div>
            </div>
          </div>
        </div>
        
        <div class="container-fluid">
          <div class="card">
            <div class="card-body">
              <form class="theme-form">
                <div class="mb-3">
                  <label class="col-form-label pt-0" for="nameInput">Название поля</label>
                  <input v-model="form.name" class="form-control" type="text" placeholder="Введите название поля">
                </div>      
                <button @click.prevent="submitForm" class="btn btn-primary">Изменить</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        form: {
          id: null,
          name: '',
          setting_id: 0,
          company_id: localStorage.getItem('company_id'),
        },

        settings: []
      };
    },
    
    created() {
      this.fetchSetting();
    },
    methods: {
        async fetchSetting() {
            try {
                const response = await this.$axios.get(`/faculties/${this.$route.params.id}`);

                this.form.name = response.data.name;
            } catch (error) {
                console.error('Ошибка загрузки настроек:', error);
            }
        },
    
      async submitForm() {
        try {
            const response = await this.$axios.put(`/faculties/${this.$route.params.id}`, this.form);
            console.log('Успешно изменено:', response.data);

            this.$router.push('/faculties');

        } catch (error) {
          console.error('Ошибка изменения:', error);
        }
      }
    }
  };
  </script>
  