import { createApp } from 'vue';
import axios from 'axios';
import router from './router';
import App from './App.vue';


const app = createApp(App);

// axios.defaults.baseURL = 'http://127.0.0.1:8000/api';
axios.defaults.baseURL = 'https://api.qr-system.ru/api';

app.use(router);
app.config.globalProperties.$axios = axios;


app.mount('#app')
