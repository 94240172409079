<template>
    <div class="page-body-wrapper">
        <div class="page-body">
            <div class="container-fluid">
                <div class="page-title">
                    <div class="row">
                        <div class="col-sm-6">
                            <h3>Изменить экзамены</h3>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container-fluid">
                <div class="card">
                    <div class="card-body">
                        <form class="theme-form">
                
                            <div v-for="pairIndex in 10" :key="'_pair' + pairIndex">
                                <br>
                                <h4>экзамен {{ pairIndex }}</h4>
                                <br>
                                <div class="mb-3">
                                    <label class="col-form-label pt-0">Преподаватель</label>
                                    <input v-model="formData[`pair${pairIndex}_teacher`]" class="form-control" type="text" placeholder="Преподаватель">
                                </div>
                                <div class="mb-3">
                                    <label class="col-form-label pt-0">Предмет</label>
                                    <input v-model="formData[`pair${pairIndex}_subject`]" class="form-control" type="text" placeholder="Предмет">
                                </div>
                                <div class="mb-3">
                                    <label class="col-form-label pt-0">Кабинет</label>
                                    <input v-model="formData[`pair${pairIndex}_cabinet`]" class="form-control" type="text" placeholder="Кабинет">
                                </div>
                                <div class="mb-3">
                                    <label class="col-form-label pt-0">Дата зачета</label>
                                    <input v-model="formData[`pair${pairIndex}_description`]" class="form-control" type="date" placeholder="Дата зачета">
                                </div>
                            </div>

                            <br>
                            <br>
                            <button @click.prevent="submitForm" class="btn btn-primary">Изменить</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>



<script>
export default {
    data() {
        return {
            formData: this.initializeFormData(),
            dep_id: null,
        };
    },

    methods: {
        initializeFormData() {
            const data = {};
            for (let pair = 1; pair <= 20; pair++) {
                data[`pair${pair}_teacher`] = '';
                data[`pair${pair}_subject`] = '';
                data[`pair${pair}_cabinet`] = '';
                data[`pair${pair}_description`] = '';
            }

            return data;
        },

        async fetchFormData() {
            try {
                const response = await this.$axios.get(`/group/${this.$route.params.id}`);

                this.dep_id = response.data.departament_id
       
                if (response.data.exameny != null) {
                    this.formData = JSON.parse(response.data.exameny);
                } else {
                    this.formData = this.initializeFormData()
                }
                
            } catch (error) {
                console.error('Ошибка загрузки данных:', error);
            }
        },

        async submitForm() {
            try {
                const response = await this.$axios.put(`/groups/exameny/${this.$route.params.id}`, {
                    exameny: JSON.stringify(this.formData)
                });
                console.log('Успешно отправлено:', response.data);

                this.$router.push(`/dep/${this.dep_id}`);
            } catch (error) {
                console.error('Ошибка отправки:', error);
            }
        },

        getDayName(dayIndex) {
            const days = ['Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота', 'Воскресенье'];
            return days[dayIndex - 1];
        }
    },

    mounted() {
        this.fetchFormData();
    }
};
</script>
