<template>
    <div class="page-body-wrapper">
      <div class="page-body">
        <div class="container-fluid">
          <div class="page-title">
            <div class="row">
              <div class="col-12">
                <h3>Добавить настройку</h3>
              </div>
            </div>
          </div>
        </div>
        <!-- Container-fluid starts-->
        <div class="container-fluid">
          <div class="card">
            <div class="card-body">
              <form class="theme-form">
                <div class="mb-3">
                  <label class="col-form-label pt-0" for="nameInput">Название факультета</label>
                  <input v-model="form.name" class="form-control" type="text" placeholder="Введите название факультета">
                </div>
                <button @click.prevent="submitForm" class="btn btn-primary">Создать</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        form: {
          name: '',
        },
      };
    },
    methods: {
      async submitForm() {
        try {
          const response = await this.$axios.post('/faculties', this.form);
          console.log('Успешно отправлено:', response.data);

            this.$router.push('/faculties');

          // Здесь вы можете выполнить другие действия после успешной отправки данных на сервер
        } catch (error) {
          console.error('Ошибка отправки:', error);
        }
      }
    }
  };
  </script>
  