<template>
    <div class="container-fluid p-0">
      <div class="row m-0">
        <div class="col-12 p-0">    
          <div class="login-card">
            <div>
              <!-- <div><a class="logo" href="index.html"><img class="img-fluid for-light" src="../assets/images/logo/logo2.png" alt="looginpage"></a></div> -->
              <div class="login-main"> 
                <form class="theme-form">
                  <h4 class="text-center">Sign in to account</h4>
                  <p class="text-center">Enter your email & password to login</p>
                  <div class="form-group">
                    <label class="col-form-label">Email Address</label>
                    <input v-model="email" class="form-control" type="email" required="" placeholder="Test@gmail.com">
                  </div>
                  <div class="form-group">
                    <label class="col-form-label">Password</label>
                    <div class="form-input position-relative">
                      <input v-model="password" class="form-control" type="password" name="login[password]" required="" placeholder="*********">
                      <div class="show-hide"><span class="show"></span></div>
                    </div>
                  </div>
                  <div class="form-group mb-0">
                    <div class="checkbox p-0">
                      <input id="checkbox1" type="checkbox">
                      <label class="text-muted" for="checkbox1">Remember password</label>
                    </div><a class="link" href="forget-password.html">Forgot password?</a>
                    <div class="text-end mt-3">
                      <button @click.prevent="login" class="btn btn-primary btn-block w-100" type="button">Sign in</button>
                    </div>
                  </div>
                  
                  <p class="mt-4 mb-0 text-center">Don't have account?<a class="ms-2" href="sign-up.html">Create Account</a></p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>


<script>
export default {
    data() {
        return {
            email: '',
            password: '',
            // menu: [
            //     { name: 'Список дел', url: '/cases' },
            //     { name: 'Список вещ доков', url: '/evids' },
            //     { name: 'Добавить дело', url: '/case/add' },
            //     { name: 'Добавить Вещ. док', url: '/evid/add' },
            //     { name: 'Выйти', url: `/logout` },
            // ],
        };
    },

    created() {
        console.log(localStorage)
        if (localStorage.getItem('company_id')) {
            this.$router.push('/');
        }
    },

    methods: {
        async login() {
            try {
                const response = await this.$axios.post('/login', {
                    email: this.email,
                    password: this.password
                });

                // Обработка успешного входа
                console.log('Успешный вход', response.data);

                localStorage.setItem('company_id', response.data['company_id']);

                this.$router.push('/');

            } catch (error) {
                // Обработка ошибок
                console.error('Ошибка входа', error.response.data);
            }
        }
    }
};
</script>

<!-- <style>
    .page-wrapper.compact-wrapper .page-header {
        display: none !important;
    }
</style> -->