
import { createRouter, createWebHistory } from 'vue-router';

// import MainPage from './components/template/MainTemplate.vue';

import Login from './components/auth/AuthLogin.vue';


import FacultyList from './components/faculty/FacultyList.vue';
import FacultyAdd from './components/faculty/FacultyAdd.vue';
import FacultyEdit from './components/faculty/FacultyEdit.vue';


import DepAdd from './components/dep/DepAdd.vue';
import DepEdit from './components/dep/DepEdit.vue';
import DepList from './components/dep/DepList.vue';


import GroupAdd from './components/group/GroupAdd.vue';
import GroupEdit from './components/group/GroupEdit.vue';
import GroupEditZachety from './components/group/GroupEditZachety.vue';
import GroupEditExameny from './components/group/GroupEditExameny.vue';




const routes = [

  // главная страница
  { path: '/', component: FacultyList },

  // авторизация
  { path: '/login', component: Login },


  // факультеты
  { path: '/faculties', component: FacultyList },
  { path: '/faculties/add', component: FacultyAdd },
  { path: '/faculties/edit/:id', component: FacultyEdit },

  // кафедры
  { path: '/dep/add', component: DepAdd },
  { path: '/dep/:id', component: DepList },
  { path: '/dep/edit/:id', component: DepEdit },

  // группы
  { path: '/groups/add', component: GroupAdd },
  { path: '/groups/edit/:id', component: GroupEdit },

  { path: '/groups/edit/zachety/:id', component: GroupEditZachety },
  { path: '/groups/edit/exameny/:id', component: GroupEditExameny },

  
];


const router = createRouter({
  history: createWebHistory(),
  routes
});


export default router;



