<template>
    <div class="page-body-wrapper">
      <div class="page-body">
        <div class="container-fluid">
          <div class="page-title">
            <div class="row">
              <div class="col-12">
                <h3>Добавить настройку</h3>
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid">
          <div class="card">
  
            <div class="card-body">
              <form class="theme-form">
                <div class="mb-3">
                  <label class="col-form-label pt-0" for="nameInput">Название кафедры</label>
                  <input v-model="form.name" class="form-control" type="text" placeholder="Введите название кафедры">
                </div>
    
                <div class="mb-3">
                  <label class="col-form-label pt-0" for="settingIdInput">Факультеты:</label>
                  <select v-model="form.faculty_id" class="form-control">
                    <option v-for="faculty in faculties" :key="faculty.id" :value="faculty.id">{{ faculty.name }}</option>
                  </select>
                </div>
         
                <button @click.prevent="submitForm" class="btn btn-primary">Создать</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        form: {
          name: '',
          faculty_id: null,
        },
        faculties: []
      };
    },
    created() {
      // Здесь можно загрузить список настроек из вашего API
      this.fetchSettings();
    },
    methods: {
      async fetchSettings() {
        try {
            const response = await this.$axios.get('/faculties');
            this.faculties = response.data;
        } catch (error) {
          console.error('Ошибка загрузки настроек:', error);
        }
      },
      async submitForm() {
        try {
            await this.$axios.post('/departaments', this.form);
  
            this.$router.push('/faculties');
        } catch (error) {
          console.error('Ошибка отправки:', error);
        }
      }
    }
  };
  </script>
  